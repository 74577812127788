<template>
  <article-page :menu="menu">
    <div class="training-content" id="new-contract">
      <div class="_subtitle">
        Создание контракта
      </div>
      <span>
        Для создания контракта нужно перевести тендер, найденный в разделе "Поиск", или заявку из раздела "Заявки" в статус "Победа".
      </span>
      <article-image :src="images[0].src" :alt="images[0].alt" @click="openViewer(images, 0)"/>
      <span>
        После этого Вам будет предложено создать карточку контракта согласно тендеру/заявке.
        При создании контракта ему автоматически присваивается статус "Ожидание проекта контракта".
        Вы можете отредактировать стоимость (изначально она равна начальной цене тендера), назначить ответственного.
      </span>
      <article-image :src="images[1].src" :alt="images[1].alt" width="500px" @click="openViewer(images, 1)"/>
    </div>
    <div class="training-content" id="contract-statuses">
      <div class="_subtitle">
        Перевод по статусам
      </div>
      <span>
        Контрактам может быть присвоен один из 10 статусов. Для удобства они объединены в три группы:
      </span>
      <ul>
        <li>
          <div> На заключении: </div>
          <ol>
            <li> Ожидание проекта контракта </li>
            <li> Контракт на подписи участника </li>
            <li> Контракт на подписи заказчика </li>
            <li> Обработка протокола разногласий </li>
          </ol>
        </li>
        <br />
        <li>
          <div> На исполнении: </div>
          <ol>
            <li value="5"> Контракт на исполнении </li>
            <li value="6"> Контракт на приемке </li>
            <li value="7"> Контракт на оплате </li>
          </ol>
        </li>
        <br />
        <li>
          <div> Завершенные: </div>
          <ol>
            <li value="8"> Контракт исполнен </li>
            <li value="9"> Контракт расторгнут </li>
            <li value="10"> Отказ от заключения контракта </li>
          </ol>
        </li>
      </ul>
      <article-image :src="images[2].src" :alt="images[2].alt" @click="openViewer(images, 2)"/>
    </div>
    <div class="training-content" id="contract-price">
      <div class="_subtitle">
        Цена контракта
      </div>
      <span>
        Цену контракта можно редактировать. Для этого необходимо нажать на иконку
        <ui-icon :icon="UiIconNames.Icon_Edit" :size="14" /> и ввести цену. Для сохранения нажмите
        <ui-icon :icon="UiIconNames.Icon_CheckUnderline" :size="14" />, для отмены внесенных изменений
        <ui-icon :icon="UiIconNames.Icon_CloseBold" :size="14" />.
      </span>
      <article-image :src="images[7].src" :alt="images[7].alt" @click="openViewer(images, 7)"/>
      <article-image :src="images[8].src" :alt="images[8].alt" @click="openViewer(images, 8)"/>
    </div>
    <div class="training-content" id="contract-dates">
      <div class="_subtitle">
        Перевод по датам
      </div>
      <span>
        При каждом переводе статуса контракта, Вам будет предложено указать плановую и фактическую дату исполнения того или иного события.
      </span>
      <article-image :src="images[3].src" :alt="images[3].alt" width="500px" @click="openViewer(images, 3)"/>
      <span>
        Даты можно будет отредактировать (иконка  <ui-icon :icon="UiIconNames.Icon_Edit" :size="14" /> - цифра 2 на картинке).
      </span>
      <span>
        Отдельно вынесена <b>дата заключения контракта</b> - это фактическая дата, привязанная к статусу "Контракт на подписи заказчика".
        При изменении даты заключения контракта, изменится и фактическая дата, привязанная к этому статусу.
      </span>
      <article-image :src="images[4].src" :alt="images[4].alt" @click="openViewer(images, 4)"/>
      <span>
        Для просмотра истории перевода контракта по датам, нажмите соответствующую кнопку (цифра 1 на картинке выше).
      </span>
      <article-image :src="images[5].src" :alt="images[5].alt" width="500px" @click="openViewer(images, 5)"/>
    </div>
    <div class="training-content" id="contract-comments">
      <div class="_subtitle">
        Комментирование
      </div>
      <span>
        Аналогично
        <accessed-link :name="Tabs.Training.Comments">комментированию тендеров в поиске</accessed-link>,
        пользователи с правами на запись могут оставлять комментарии к каждому контракту. Уже написанные комментарии можно добавлять в избранное и удалять из избранного
        нажатием на иконку <ui-icon :icon="UiIconNames.Icon_StarO" :size="14" />. Для удаления комментария нажмите на иконку <ui-icon :icon="UiIconNames.Icon_Delete" :size="14" />.
      </span>
      <span>
        Обратите внимание, что комментарии к тендеру в разделе "Контракты" отличаются от комментариев в остальных разделах.
        Комментарий, написанный в разделе контрактов, не будет отображаться в разделах поиска и заявок.
      </span>
      <article-image :src="images[6].src" :alt="images[6].alt" @click="openViewer(images, 6)"/>
    </div>
    <div class="training-content" id="contract-responsible">
      <div class="_subtitle">
        Назначение ответственного
      </div>
      <span>
        На каждый созданный контракт можно назначить ответственного. Пользователь с правами "Администратор" или "Чтение и запись" может назначать ответственным как себя, так и других сотрудников компании
        (подробнее рассказано в статье
        <accessed-link :name="Tabs.Training.UserManagement"> Совместная работа</accessed-link>).
      </span>
      <span>
        Пользователь может получать рассылку по событиям, связанным с его контрактами (теми контрактами, на которые он назначен ответственным).
        Включить и отключить рассылку можно в разделе
        <accessed-link :name="Tabs.Settings.Notifications" target="_blank"> Настройки уведомлений</accessed-link>.
        Уведомления направляются по часовому поясу получателя в следующем порядке:
      </span>
      <ul>
        <li>
          <div>
            Для контрактов со статусом <b>«Контракт на подписи участника»</b>:
          </div>
          <br />
          <ol>
            <li> В выбранное время рабочего дня, предшествующего дню истечения подписания контракта (за день истечения считается плановая дата, привязанная к указанному статусу); </li>
            <li> В выбранное время даты истечения подписания контракта. </li>
          </ol>
        </li>
        <br />
        <div>
          Примечание: За дату подписания контракта считается дата, проставленная в поле "фактическая дата", привязанная к статусу "Контракт на подписи участника".
        </div>
      </ul>
      <span>
        Для активации отправки, поставьте галочку напротив нужного уведомления (цифра 1 на картинке ниже).
      </span>
      <span>
        Время отправки уведомления можно поменять - для этого необходимо нажать на иконку
        <ui-icon :icon="UiIconNames.Icon_Cog" :size="14" /> напротив необходимого уведомления (цифра 2 на картинке ниже),
        выбрать удобное время по вашему часовому поясу и нажать кнопку <b>Сохранить</b>.
        Часовой пояс можно поменять в разделе
        <accessed-link :name="Tabs.Settings.Account" target="_blank"> Мой профиль</accessed-link>.
      </span>
      <article-image :src="images[9].src" :alt="images[9].alt" @click="openViewer(images, 9)"/>
    </div>
    <div class="training-content" id="contract-calculation">
      <div class="_subtitle">
        Форма расчета
      </div>
      <span>
        Для каждого контракта Вы можете скачать excel файл, содержащий необходимую информацию для удобного расчета стоимости.
        В будущих версиях сайта планируется добавить возможность загрузки отредактированных файлов расчета.
        Это обеспечит хранение и быстрый доступ пользователей к нужным файлам.
      </span>
    </div>
    <div class="training-content" id="contract-filters">
      <div class="_subtitle">
        Панель фильтров
      </div>
      <span>
        При открытии разделов загружаются все контракты, соответствующие разделу (цифра 1 на картинке ниже):
      </span>
      <ul>
        <li> Раздел "Контракты" - все созданные контракты; </li>
        <li> Подвкладка "На заключении" - заключенные контракты со статусами 1-4; </li>
        <li> Подвкладка "На исполнении" - контракты на исполнении, со статусами 5-7; </li>
        <li> Подвкладка "Завершенные" - завершенные контракты со статусами 8-10; </li>
      </ul>
      <span>
        При необходимости, лишние разделы можно скрыть в
        <accessed-link :name="Tabs.Settings.InterfaceSettings" target="_blank">Настройках функциональности</accessed-link>.
      </span>
      <span>
        Для поиска нужных контрактов предусмотрены фильтры и сортировки. Логика работы такая же, как в разделе заявок:
      </span>
      <ul>
        <li>
          <div> Селектор шаблонов (цифра 2 на картинке ниже)</div>
          <br />
          <div>
            Селектор шаблонов аналогичен селектору в поиске (см.
            <accessed-link :name="Tabs.Training.Templates">Работа с шаблонами</accessed-link>),
            однако предназначен только для фильтрации контрактов.
            Выбрав шаблоны/группы шаблонов и нажав на кнопку "Применить", контракты отфильтруются в соответствии с выбранными вариантами.
            Для фильтрации так же доступен служебный шаблон "Закупки без шаблона", который содержит созданные контракты, которые не принадлежат ни одному шаблону.
          </div>
        </li>
        <br />
        <li>
          <div> Панель фильтров и сортировок (цифра 3 на картинке ниже)</div>
          <br />
          <div>
            В каждом разделе Вам доступны 5 фильтров в панели сортировок - фильтр по размерам обеспечений (обеспечение исполнения контракта, размер гарантийных обязательств),
            фильтр по датам (планируемая и фактическая дата), фильтр по номеру закупки, местам поставки и ИНН заказчика, а так же фильтры и сортировки по ценам и статусам контракта.
            Как и в заявках, результаты можно скачать в формате excel.
          </div>
          <br />
          <div>
            Примечание: Если изменить выбор в селекторе шаблона при наличии фильтров или сортировок в панели фильтров, то панель фильтров сбросится до начального состояния.
          </div>
          <br />
          <div>
            Примечание: Фильтр по планируемой и фактической датам работает следующим образом - на экране вы видите даты, привязанные к текущему статусу контракта,
            фильтр сравнивает введенные Вами значения с отображаемыми датами, и показывает результат. Даты, привязанные к другим статусам, игнорируются.
          </div>
        </li>
      </ul>
      <article-image :src="images[10].src" :alt="images[10].alt" @click="openViewer(images, 10)"/>
    </div>
  </article-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useImageViewer } from "@/use/other/useImageViewer";
import ArticlePage from "@/components/pages/training/ArticlePage.vue";
import ArticleImage from "@/components/pages/training/ArticleImage.vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import { Tabs } from "@/router/tabs";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import AccessedLink from "@/components/ui/links/AccessedLink.vue";

export default defineComponent({
  name: "ContractsArticle",
  components: {
    AccessedLink,
    UiIcon,
    ArticlePage,
    ArticleImage,
  },
  setup() {

    const menu = [
      { id: 'new-contract', title: 'Создание контракта' },
      { id: 'contract-statuses', title: 'Перевод по статусам' },
      { id: 'contract-price', title: 'Цена контракта' },
      { id: 'contract-dates', title: 'Перевод по датам' },
      { id: 'contract-comments', title: 'Комментирование' },
      { id: 'contract-responsible', title: 'Назначение ответственного' },
      { id: 'contract-calculation', title: 'Форма расчета' },
      { id: 'contract-filters', title: 'Панель фильтров' },
    ]

    const images = [
      { src: 'contracts/new-contract.png', alt: 'Перевод тендера в статус "Победа"' },
      { src: 'contracts/new-contract-dialog.png', alt: 'Создание контракта' },
      { src: 'contracts/statuses.png', alt: 'Статусы контракта' },
      { src: 'contracts/date-selector.png', alt: 'Выбор дат при переводе статуса контракта' },
      { src: 'contracts/card-dates.png', alt: 'Карточка контракта' },
      { src: 'contracts/date-history.png', alt: 'История перевода по датам' },
      { src: 'contracts/card-comments.png', alt: 'Комментирование контракта' },
      { src: 'contracts/card-price.png', alt: 'Редактирование цены контракта (1)' },
      { src: 'contracts/card-price-edit.png', alt: 'Редактирование цены контракта (2)' },
      { src: 'contracts/contract-mailing.png', alt: 'Настройка уведомлений' },
      { src: 'contracts/sections.png', alt: 'Раздел "Контракты"' },
    ]

    const { openViewer } = useImageViewer();

    return {
      menu,
      images,
      Tabs,
      openViewer,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';
</style>
